<template>
  <div class="dswrp" @mousedown="$emit('clicked')">
    <div class="tp">
      <div class="monthName">{{ curMonth }}</div>
      <div class="btnsWrp">
        <div class="navBtn prew" @click="onPrewDate">
          <i class="ic-arrow-drop"></i>
        </div>
        <div class="navBtn next" @click="onNextDate">
          <i class="ic-arrow-drop"></i>
        </div>
      </div>
    </div>
    <div class="dsbody">
      <div class="daysHead">
        <div v-for="index in 7" :key="index">
          {{ $t(`calendarDays.${index}`) }}
        </div>
      </div>
      <div class="days">
        <div
          class="day beforeday"
          v-for="index in firstDayName"
          :key="`db${index}`"
        >
          {{ daysBefore - (firstDayName - index) }}
        </div>
        <day-cell
          v-for="index in days"
          :key="`d${index}`"
          :date="getDate(index)"
          :selectedDate="selectedDate"
          @click="onDaySelect"
        ></day-cell>
        <template v-if="lastDayName != 6">
          <div
            class="day afterday"
            v-for="index in 7 - (lastDayName + 1)"
            :key="`ab${index}`"
          >
            {{ index }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import DayCell from "./DayCell.vue";
export default {
  components: { DayCell },
  name: "DateSelect",
  props: ["focusDate", "initialDate"],
  data() {
    return {
      selectedDate: null,
      date: null
    };
  },
  methods: {
    getDate(day) {
      return new Date(
        `${Intl.DateTimeFormat("en", {
          month: "long"
        }).format(new Date(this.date))} ${day} ${Intl.DateTimeFormat("en", {
          year: "numeric"
        }).format(new Date(this.date))}`
      );
    },
    onDaySelect(e) {
      this.selectedDate = e;
      this.$emit("select", e);
    },
    onPrewDate() {
      let a = this.date.getMonth();
      this.date = new Date(this.date.setMonth(a - 1));
    },
    onNextDate() {
      let a = this.date.getMonth();
      this.date = new Date(this.date.setMonth(a + 1));
    }
  },
  computed: {
    days() {
      if (this.date)
        return new Date(
          this.date.getFullYear(),
          this.date.getMonth() + 1,
          0
        ).getDate();
      else return null;
    },
    daysBefore() {
      if (this.date)
        return new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          0
        ).getDate();
      else return null;
    },
    firstDayName() {
      if (this.date)
        return new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          1
        ).getUTCDay();
      else return null;
    },
    lastDayName() {
      if (this.date)
        return new Date(
          this.date.getFullYear(),
          this.date.getMonth() + 1,
          0
        ).getUTCDay();
      else return null;
    },
    curMonth() {
      return `${Intl.DateTimeFormat(this.$i18n.locale, {
        month: "long"
      }).format(new Date(this.date))} ${Intl.DateTimeFormat(this.$i18n.locale, {
        year: "numeric"
      }).format(new Date(this.date))}`;
    }
  },
  watch: {
    initialDate(newValue) {
      if (newValue) {
        this.selectedDate = newValue;
      } else {
        this.selectedDate = null;
      }
    }
  },
  created() {
    if (this.initialDate) this.selectedDate = this.initialDate;
  },
  mounted() {
    // this.date = new Date("2.1.2021");
    this.date = this.focusDate || new Date(new Date().setDate(1));
    this.date = new Date(
      `${Intl.DateTimeFormat("en", {
        month: "long"
      }).format(new Date(this.date))} 1 ${Intl.DateTimeFormat("en", {
        year: "numeric"
      }).format(new Date(this.date))}`
    );
  }
};
</script>

<style lang="sass" scoped>
.dswrp
  background: #ffffff
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)
  border: 1px solid #4AA7BC
  box-sizing: border-box
  padding: 30px 14px
  width: 393px
  .tp
    display: flex
    justify-content: space-between
    align-items: center
    .monthName
      // @include t4
      text-transform: capitalize

    .btnsWrp
      display: flex
      .navBtn
        width: 40px
        height: 40px
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer
        &.prew
          transform: rotate(180deg)

        &.next
          // background-color: $cgray6




  .dsbody
    .daysHead
      display: grid
      grid-template-columns: repeat(7, 1fr)
      div
        display: flex
        justify-content: center
        align-items: center
        height: 54px

        font-style: normal
        font-weight: 600
        font-size: 16px
        line-height: 130%
        color: #777777

    .days
      display: grid
      grid-template-columns: repeat(7, 1fr)
      .beforeday,
      .afterday
        // color: $cgray3
        pointer-events: none
        display: flex
        justify-content: center
        align-items: center
        opacity: 0
</style>
