<template>
  <div class="tawrp">
    <label
      :for="`input${_uid}`"
      class="inputComponent"
      :class="{ isError: isError, active: isFocus }"
    >
      <textarea
        :id="`input${_uid}`"
        :type="itype ? itype : 'text'"
        v-model.trim="value"
        @keyup="onKeyUp"
        :name="propname"
        @focus="onFocus"
        @blur="onBlur"
        :placeholder="placeholder"
        :class="{active: isFocus}"
      ></textarea>
      <!-- <span class="labeltxt" :class="{ active: value != '' || isFocus }">{{
				placeholder
			}}</span> -->
    </label>
    <span class="tip" v-if="isError">{{ isError }}</span>
    <span class="desc">{{ description }}</span>
  </div>
</template>

<script>
import defaultinput from "./DefaultInputMixin.js";
export default {
  name: "TextArea",
  mixins: [defaultinput],
  props: {
    description: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="sass" scoped>
textarea
  height: 80px
  width: 100%
  border: none
  background-color: #fff
  outline: none
  padding: 18px 15px
  resize: none
  display: block

  font-style: normal
  font-weight: 500
  font-size: 15px
  line-height: 130%
  letter-spacing: 0.01em
  color: #111325
  &.active
    box-shadow: 0 0 0px 2px $c-btn

  &::placeholder
    color: #ABA7A7
// 	&.isError {
// 		border: 2px solid $cred1;
// 	}
// 	.labeltxt {
// 		position: absolute;
// 		top: 30px;
// 		left: 20px;
// 		font-style: normal;
// 		font-weight: 500;
// 		font-size: 15px;
// 		line-height: 130%;
// 		letter-spacing: 0.01em;
// 		color: #111325;
// 		transform: translate(0, -50%);
// 		transition: 0.1s ease;
// 		&.active {
// 			top: -20px;
// 			left: 15px;
// 			transform: unset;
// 			@media (max-width: $tm) {
// 				top: unset;
// 				bottom: 100%;
// 			}

// 			font-style: normal;
// 			font-weight: 500;
// 			font-size: 14px;
// 			line-height: 130%;
// 			letter-spacing: 0.02em;
// 			color: #585b6d;
// 		}
// 	}
// }
.tip
  color: #ff2e2e
  display: block

// .desc{
// 	@include t4;
// 	color: $cgray3;
// }
</style>
