import { TheMask } from "vue-the-mask";
export default {
  components: {
    TheMask
  },
  props: [
    "mask",
    "placeholder",
    "itype",
    "errors",
    "propname",
    "validate",
    "showError",
    "wrpstyle",
    "mode",
    "prevalue",
    "handler",
    "title"
  ],
  data() {
    return {
      value: "",
      labelWidth: 0,
      isFocus: false
    };
  },
  methods: {
    onKeyUp() {
      if (this.propname != null) {
        // this.$emit("change", {
        // 	name: this.propname,
        // 	value: this.value
        // });
        this.$emit("input", this.value);
      }
    },
    onBtnClc() {
      if (this.mode == "button") {
        this.$emit("submit");
      }
    },
    onFocus() {
      this.isFocus = true;
    },
    onBlur() {
      this.isFocus = false;
    },
    handlerFunc() {
      if (this.handler == "phone")
        this.value = this.value.replace(/[^0-9.]/g, "");
    }
  },
  computed: {
    isMaskedInput() {
      if (this.mask !== undefined && this.mask !== null && this.mask !== "")
        return true;
      else return false;
    },
    isError() {
      if (this.validate) {
        if (this.validate.$invalid && this.showError) {
          if (this.validate.required === false) {
            return this.$t("fieldErrors.required");
          }
          if (this.validate.minLength === false) {
            return this.$t("fieldErrors.minLength", {
              length: this.validate.$params.minLength.min
            });
          }
          if (this.validate.maxLength === false) {
            return this.$t("fieldErrors.maxLength", {
              length: this.validate.$params.maxLength.max
            });
          }
          if (this.validate.email === false) {
            return this.$t("fieldErrors.email");
          }
          return "some error";
        } else return null;
      } else return null;
    },
    btnClass() {
      if (this.mode == "button") {
        if (this.value.trim() != "") {
          return "active";
        }
      } else return "";
    }
  },
  mounted() {
    setTimeout(() => {
      this.value = this.prevalue ? this.prevalue : "";
    }, 200);
  }
};
